<template>
  <div>
    <HomeHeader />

    <v-alert style="background-color:#b01c3f" class="mx-3 mt-4" dark>
      <div
        class="d-flex flex-wrap justify-space-between align-center gap-x-4 gap-y-1"
      >
        <div>
          <div class="d-flex align-center gap-2">
            <img
              src="@/assets/images/logo/logo-ticketme-light-old.png"
              contain
              height="20"
            />
            <v-icon>mdi-arrow-right</v-icon>
            <img
              src="@/assets/images/logo/logo-light.png"
              contain
              height="18"
            />
          </div>
          <span class="text-overline lh-1">SEU UNIVERSO DAS ATLÉTICAS</span>
        </div>
        <p class="mb-0">
          A TicketMe, agora focada em atender as Atléticas, está de cara nova!
        </p>
      </div>
    </v-alert>

    <!-- <pillars @goTo="goTo" /> -->
    <gallery-highlight />
    <party-list />

    <!-- <highlighted /> -->
    <!-- <features ref="features" /> -->
    <!-- <testimony /> -->
  </div>
</template>

<script>
import HomeHeader from "../../components/home/Header.vue";
import Pillars from "../../components/home/Pillars.vue";
import Highlighted from "../../components/home/Highlighted.vue";
import Features from "../../components/home/Features.vue";
import GalleryHighlight from "../../components/home/GalleryHighlight.vue";
import PartyList from "../shop/Index.vue";

// import Testimony from "../../components/home/Testimony.vue";
export default {
  metaInfo: {
    title: "Home",
  },
  components: {
    HomeHeader,
    Pillars,
    Highlighted,
    Features,
    GalleryHighlight,
    PartyList,
  },
  name: "Home",
  methods: {
    goTo({ hash }) {
      this.$refs.features.goTo(hash);
      const el = this.$refs.features;
      this.$vuetify.goTo(el, {
        offset: 30,
        behavior: "smooth",
      });
    },
  },
};
</script>
